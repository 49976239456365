import { useState, useEffect, useMemo, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import Header from "./container/Units/Header.jsx";
import Footer from "./container/Units/Footer.jsx";
import Cookies from "js-cookie";
import Login from "./container/Login/Login.jsx";
import SignUp from "./container/Login/SignUp.jsx";
import NewRouter from "./NewRouter.jsx";
import ScrollToTop from "./units/ScrollToTop.js";
import './index.css'
import './base.css'
import './App.css';
import { Routes,Route } from "react-router-dom";
import { UserProvider, UserContext } from "./userProvider.jsx";
import Greeting from "./container/Login/Greeting.jsx";
import CookieConsent from './components/Cookies/CookiesConsent';
import ForgotPassword from "./container/Login/ForgotPassword.jsx";

function App() {
  // const display = !['/greeting'].some(substring => location.pathname.includes(substring))
  const [showTop, setShowTop] = useState(false)
  const { t, i18n } = useTranslation();
  const language = i18n.language
  const newsData = useMemo(() => t('news.source', {returnObjects:true}), [t]);
  const navigate = useNavigate()
  const [user, setUser] = useState({});
  const [activeLang, setActiveLang] = useState(0)
  // const userData = JSON.parse(localStorage.getItem('userData'))
  const {saveUserData, getUserData} = useContext(UserContext);
  
  const changeLanguage = (language) => {
    const currentScrollPosition = window.scrollY; 
    
    i18n.changeLanguage(language, () => {
      Cookies.set('selectedLanguage', language, { expires: 365 });
      const currentPath = location.pathname;
      const newPath = currentPath.replace(/^\/(en|ja|vi|ko)/, `/${language}`);
      
      if (currentPath !== newPath) {
        navigate(newPath, { replace: true });
        
        setTimeout(() => {
          window.scrollTo({ top: currentScrollPosition, behavior: 'instant' });
        }, 0);
      }
    });
  };
  const handleActiveLang = (index) => {
    setActiveLang(index)
  }
  useEffect(() => {
    switch (language) {
      case 'en': 
        handleActiveLang(0);
        break;
      case 'ja': 
        handleActiveLang(1);
        break;
      case 'vi': 
        handleActiveLang(2);
        break;
      case 'ko': 
        handleActiveLang(3);
        break;
      default:
        break;
    }
  }, [language]);

    useEffect(()=>{
      const savedLang = Cookies.get('selectedLanguage');
      if(savedLang && i18n.language !== savedLang){
        i18n.changeLanguage(savedLang)
        }
    })
  const [news, setNews] = useState([])

  useEffect(() =>{
              const handleScroll = () => {
                  setShowTop(window.scrollY >= 1000)
              }
              window.addEventListener('scroll', handleScroll);
      
              return () => {
              window.removeEventListener('scroll', handleScroll);
              }
          }
  )

  function scrollToTop() {
             window.scrollTo({top:0, behavior: 'smooth'})
          }
          useEffect(() => {
            setNews(newsData)
          },[newsData])

if (process.env.NODE_ENV === 'production') {
  console.warn = () => {};
  console.error = () => {};
}
// useEffect(()=> {
//   console.log('%cWelcome to Azumaya Hotel, valued guest !', 'color: #482979; font-size: 30px; font-weight: bold;');
//   console.log(`%cWarning: Modifying website values ​​via browser inspect tool is prohibited and may result in unintended behavior. 
// Please refrain from altering website code directly !`, 'color: orange; font-size: 25px; font-weight: bold;');
//   console.log('%cIf you encounter any issues with your experience on the Azumaya website, please do not hesitate to contact us directly !', 'color: red; font-size: 20px; font-weight: bold; font-style: italic');
//   console.log('%c東屋ホテルへようこそ！', 'color: #482979; font-size: 30px; font-weight: bold;');
//   console.log(`%c警告: ブラウザ検査ツールを使用してウェブサイトの値を変更することは禁止されており、意図しない動作が発生する可能性があります。
// ウェブサイトのコードを直接変更することは控えてください。`, 'color: orange; font-size: 25px; font-weight: bold;');
//   console.log('%c東屋ウェブサイトをご利用中に問題が発生した場合は、お気軽に直接お問い合わせください。', 'color: red; font-size: 20px; font-weight: bold; font-style: italic');
//   console.log('%cE-mail: it3@azumayavietnam.com', 'color: black; font-size: 20px');
// }, [])


// useEffect(() => {
//   if (userData) {
//     const userObj = {
//       email: userData.email,
//       password: userData.password,
//     };
//     setUser(userObj);
//   }
// }, [userData]);
  // const postDataMail = async (user) => {
  //     try {
  //       const response = await fetch('http://localhost:3000/api/user/a4g17146-gg44-4323-135d-29at4qaf214w', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify(user), 
  //       });
  
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       const responseData = await response.json();
  //       saveUserData(responseData)
  //     } catch (error) {
  //       console.error('Error:', error);
  //     }
  //   };
    
  // useEffect(() => {
  //   // postDataMail(user)
  //   getUserData();
  // }, []);
  const userData = JSON.parse(localStorage.getItem('userData'))
  useEffect(() => {
    if (userData) { 
      if (userData.status === 'unknown') {
        localStorage.removeItem('userData');
      } else {
        getUserData();
      }
    }}, [])
  return (
    <div>
       {/* <Routes>
       <Route path='/greeting' element={<Greeting />} />
      </Routes> */}
      {/* {display &&( */}
        <div>
        <Header />
        <CookieConsent />
    <div className ="top">
            {showTop && (
             <>
            <button className="btn__top" onClick={scrollToTop}>
            <i className="fa-solid fa-angle-up"></i>
            </button>
            {location.pathname.includes("/recruitment") ? (
              <>
              <button className ='btn-vie btn_vie-fixed' style={{bottom: 80}} onClick={()=>{
                changeLanguage('vi')
                }}></button>  
              </>
            ) : (
              <>
              <button className ='btn-en btn_en-fixed' style={{opacity: activeLang === 0 ? 1 : .3}} onClick={()=>{
                handleActiveLang(0)
                changeLanguage('en')
              }}></button>                
              <button className ='btn-ja btn_ja-fixed' style={{opacity: activeLang === 1 ? 1 : .3}} onClick={()=>{
                handleActiveLang(1)
                changeLanguage('ja')
                }}></button>  
              <button className ='btn-vie btn_vie-fixed' style={{opacity: activeLang === 2 ? 1 : .3}} onClick={()=>{
                handleActiveLang(2)
                changeLanguage('vi')
                }}></button>  
                <button className ='btn-kor btn_kor-fixed' style={{opacity: activeLang === 3 ? 1 : .3}} onClick={()=>{
                  handleActiveLang(3)
                changeLanguage('ko')
                }}></button> 
              </>
            )}
            </>        
                    )}
    </div>
      <ScrollToTop x={0} y={0} />
      <NewRouter news={news}/>
      <Footer />
      </div>
        {/* )} */}
          </div>
        )
}

export default App


