import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
export default function AppPolicy() {
  const { t } = useTranslation();
  return (
    <>
      <div className="reservation__content">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Application Policy</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: 50 }}>
        <div className="row">
          <div className="policy-container">
            <div className="container">
              <h1>
                <strong>Application Policy</strong>
              </h1>
              <p>{t("privacy.lastUpdate")} 14-01-2025</p>
              <h3>
                <strong>1. Information We Collect</strong>
              </h3>
              <p>
                We collect the following types of information when you use the
                Azumaya Hotel App:
              </p>
              <ul>
                <li>
                  <p>
                    <strong>Name:</strong> To personalize your experience and
                    address you correctly.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Email Address:</strong> To communicate with you,
                    send booking confirmations, and provide updates.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Gender:</strong> To tailor our services and offers
                    to your preferences.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Birthdate:</strong> To provide special offers or
                    discounts on your birthday and verify your age if required.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Current Location:</strong> To provide location-based
                    services, such as nearby attractions or directions to the
                    hotel.
                  </p>
                </li>
              </ul>

              <h3>
                <strong>2. How We Use Your Information</strong>
              </h3>
              <p>
                We use the information we collect for the following purposes:
              </p>
              <ul>
                <li>
                  <p>
                    To provide, maintain, and improve the Azumaya Hotel App.
                  </p>
                </li>
                <li>
                  <p>
                    To personalize your experience and offer tailored
                    recommendations.
                  </p>
                </li>
                <li>
                  <p>
                    To communicate with you, including sending booking
                    confirmations, updates, and promotional offers.
                  </p>
                </li>
                <li>
                  <p>
                    To analyze usage trends and improve the functionality of the
                    App.
                  </p>
                </li>
                <li>
                  <p>
                    To comply with legal obligations and protect the rights and
                    safety of our users.
                  </p>
                </li>
              </ul>

              <h3>
                <strong>3. Sharing Your Information</strong>
              </h3>
              <p>
                We do not sell, trade, or rent your personal information to
                third parties. However, we may share your information in the
                following circumstances:
              </p>
              <ul>
                <li>
                  <p>
                    <strong>Service Providers:</strong> With third-party vendors
                    who assist us in operating the App (e.g., payment
                    processors, analytics providers).
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Legal Requirements:</strong> When required by law or
                    to protect our rights, property, or safety.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Business Transfers:</strong> In the event of a
                    merger, acquisition, or sale of assets, your information may
                    be transferred to the new owner.
                  </p>
                </li>
              </ul>

              <h3>
                <strong>4. Data Security</strong>
              </h3>
              <p>
                We take the security of your information seriously and implement
                appropriate technical and organizational measures to protect it.
                However, no method of transmission over the internet or
                electronic storage is 100% secure, and we cannot guarantee
                absolute security.
              </p>

              <h3>
                <strong>5. Your Rights</strong>
              </h3>
              <p>
                Depending on your location, you may have the following rights
                regarding your personal information:
              </p>
              <ul>
                <li>
                  <p>
                    <strong>Access:</strong> Request a copy of the personal data
                    we hold about you.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Correction:</strong> Request corrections to
                    inaccurate or incomplete data.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Deletion:</strong> Request the deletion of your
                    personal data.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Opt-Out:</strong> Opt out of receiving promotional
                    communications.
                  </p>
                </li>
              </ul>
              <p>
                To exercise these rights, please contact us at{" "}
                <a href="it3@azumayavietnam.com">
                    it3@azumayavietnam.com
                </a>
                .
              </p>

              <h3>
                <strong>6. Third-Party Links</strong>
              </h3>
              <p>
                The Azumaya Hotel App may contain links to third-party websites
                or services. We are not responsible for the privacy practices or
                content of these third parties. We encourage you to review their
                privacy policies before providing any personal information.
              </p>

              <h3>
                <strong>7. Children's Privacy</strong>
              </h3>
              <p>
                The Azumaya Hotel App is not intended for use by individuals
                under the age of 13. We do not knowingly collect personal
                information from children under 13. If we become aware that we
                have collected such information, we will take steps to delete it
                promptly.
              </p>

              <h3>
                <strong>8. Changes to This Privacy Policy</strong>
              </h3>
              <p>
                We may update this Privacy Policy from time to time. Any changes
                will be posted on this page with an updated "Last Updated" date.
                We encourage you to review this policy periodically to stay
                informed about how we are protecting your information.
              </p>

              <h3>
                <strong>9. Contact Us</strong>
              </h3>
              <p>
                If you have any questions or concerns about this Privacy Policy
                or our data practices, please contact us at:
              </p>
              <p>
                <strong>Azumaya Hotel</strong>
                <br />
                Email:{" "}
                <a href="it3@azumayavietnam.com">
                  it3@azumayavietnam.com
                </a>
                <br />
                Phone: 0839098505
                <br />
                Address: 68 Pham Huy Thong, Ba Dinh, Ha Noi
              </p>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </>
  );
}
